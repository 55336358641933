import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "allianzReports _max940"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Fertige Bewertungen " + _vm._s(_vm.globalSearch))])]), _c('div', {
    staticClass: "card-content ovauto"
  }, [_c('div', {
    staticClass: "fitem fr"
  }, [_c('div', {
    staticClass: "wz-tag"
  }, [_c('span', {
    staticClass: "_lbluep"
  }, [_vm._v(_vm._s(_vm.filteredList.length))]), _vm._v("Akte auf"), _c('span', {
    staticClass: "_lbluep"
  }, [_vm._v(_vm._s(_vm.fPages.length))]), _vm._v("Seiten Akte pro Seite")]), _c('v-pagination', {
    attrs: {
      "fPages": _vm.fPages
    },
    model: {
      value: _vm.onpage,
      callback: function callback($$v) {
        _vm.onpage = _vm._n($$v);
      },
      expression: "onpage"
    }
  }), _c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "wz-tag"
  }, [_c('span', [_vm._v("Akte pro Seite:")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.iperpage = 10;
      }
    }
  }, [_vm._v("10")]), _c('span', [_vm._v("  ")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.iperpage = 30;
      }
    }
  }, [_vm._v("30")]), _c('span', [_vm._v("  ")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.iperpage = 100;
      }
    }
  }, [_vm._v("100")]), _c('span', [_vm._v("  ")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.iperpage = 250;
      }
    }
  }, [_vm._v("250")])])])], 1), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("LD Filter:")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selLd,
      expression: "selLd"
    }],
    staticClass: "input",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selLd = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("\"ALLE LD\"")]), _vm._l(_vm.ldList, function (ld) {
    return _c('option', [_vm._v(_vm._s(ld))]);
  })], 2), _c('div', {
    staticClass: "clear"
  })]), _c('div', {
    staticClass: "fl"
  }, [_c('button', {
    staticClass: "btn _green",
    on: {
      "click": function click($event) {
        return _vm.saveReportDldIds();
      }
    }
  }, [_vm._v("Merkdaten speichern")])])])]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_vm._fbReports ? _c('ul', _vm._l(_vm.fPages[_vm.onpage - 1], function (item, id) {
    return _c('li', {
      staticClass: "report-li",
      class: {
        _opened: false,
        _done: item.has_svinfo
      }
    }, [_c('div', {
      staticClass: "fl"
    }, [_c('v-checkbox', {
      staticClass: "bold",
      attrs: {
        "item": item.id
      },
      model: {
        value: _vm.reportsDldIds,
        callback: function callback($$v) {
          _vm.reportsDldIds = $$v;
        },
        expression: "reportsDldIds"
      }
    }), _c('span', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(_vm.aktName(item)))]), _c('br'), _c('span', [_vm._v(_vm._s(item.vorname) + " " + _vm._s(item.name))]), _c('br'), _c('small', {
      staticClass: "bold color-lightgrey"
    }, [_vm._v(_vm._s(item.sv1))]), _c('br'), _c('small', {
      staticClass: "bold color-lightgrey"
    }, [_vm._v(_vm._s(item.sv2))])], 1), _c('div', {
      staticClass: "fr tar"
    }, [_c('a', {
      staticClass: "link bold",
      attrs: {
        "href": _vm.$env.mainurl + 'sharepoint/Allianz/' + item.id + '/Dokumente/Bewertung-' + item.polnr + '.pdf' + _vm.tok,
        "target": "_blank",
        "download": ""
      }
    }, [_vm._v("Bewertung laden")]), _c('br'), _c('small', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(item.polnr || item.id))]), _c('br'), _c('small', {
      staticClass: "condensed"
    }, [_vm._v("  (" + _vm._s(_vm.$dayjs(item.modified).format(_vm.MAIN_ONLY_DATE_FORMAT)) + ")")]), _c('small', {
      staticClass: "condensed"
    }, [_vm._v("  " + _vm._s(item.ld))])]), _c('div', {
      staticClass: "clear"
    })]);
  }), 0) : _vm._e()])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };